const apiKey = process.env.VUE_APP_GOOGLE_API_KEY

if (!apiKey) {
  console.warn('[GOOGLE API] process.env.VUE_APP_GOOGLE_API_KEY is missed')
}

const service = new google.maps.places.AutocompleteService()

export const getCitiesAutocompletion = (
  input: string,
  code: string
): Promise<google.maps.places.AutocompleteResponse> => {
  return service.getPlacePredictions({
    input,
    componentRestrictions: { country: code },
  })
}
